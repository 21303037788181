import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { stratify, tree } from 'd3-hierarchy'
import ReactFlow, {
  Background,
  BackgroundVariant,
  useEdgesState,
  useNodesState,
} from 'reactflow'

import { toast } from '@redwoodjs/web/toast'

import InformerOrgChartMenuBar from 'src/components/InformerOrgChart/InformerOrgChartMenuBar'
import InformerOrgChartSidePanel from 'src/components/InformerOrgChart/InformerOrgChartSidePanel'
import InformerOrgChartStructureNode from 'src/components/InformerOrgChart/InformerOrgChartStructureNode'
import 'reactflow/dist/style.css'
import Button from 'src/components/Library/Button/Button'
import useOrgChartFlowStore from 'src/lib/stores/orgChartFlowStores'
import { useAuth } from 'src/Providers'

import {
  CreateOrgChartLayoutType,
  DeleteOrgChartLayoutType,
  UpdateOrgChartLayoutType,
} from './InformerOrgChartCell/InformerOrgChartCell'
import InformerOrgChartSharingModal from './InformerOrgChartSharingModal'
import InformerOrgChartTopMenuBar from './InformerOrgChartTopMenuBar'

const ORG_NODE_ID = 'ORG_NODE'

// Define a type for the nodes
export interface NodeType {
  id: string
  [key: string]: any
}

type Props = {
  createOrgChartLayout: CreateOrgChartLayoutType
  updateOrgChartLayout: UpdateOrgChartLayoutType
  deleteOrgChartLayout: DeleteOrgChartLayoutType
  structureId: number
  memberships: any
  dbList: any
}

const InformerOrgChart: FC<Props> = ({
  createOrgChartLayout,
  updateOrgChartLayout,
  deleteOrgChartLayout,
  structureId,
  memberships,
  dbList,
}) => {
  const fullView = useOrgChartFlowStore((state) => state.fullView) // State fo if the whole node should be shown or just the small node
  const setFullView = useOrgChartFlowStore((state) => state.setFullView)
  const structureView = useOrgChartFlowStore((state) => state.structureView) // State of if the user is in structure view mode

  const expandedNodes = useOrgChartFlowStore((state) => state.expandedNodes) // State of the nodes that are expanded

  const editNode = useOrgChartFlowStore((state) => state.editNode) // State of if the user is editing a node
  const setAvailableUsers = useOrgChartFlowStore(
    (state) => state.setAvailableUsers,
  ) // Function to set the state of availableUsers
  const setAllUsers = useOrgChartFlowStore((state) => state.setAllUsers) // Available staff, those who have yet to be allocated, which will appear at the top of the list
  const setAllocatedUsers = useOrgChartFlowStore(
    (state) => state.setAllocatedUsers,
  ) // Users that have already been allocated to a seat, which will appear at the bottom of the list

  const setAllPositions = useOrgChartFlowStore((state) => state.setAllPositions) // Function to set the state of allPositions
  const viewFromPosition = useOrgChartFlowStore(
    (state) => state.viewFromPosition,
  ) // State of the position to view from (focussed view)
  const setViewFromPosition = useOrgChartFlowStore(
    (state) => state.setViewFromPosition,
  ) // Function to set the state of viewFromPosition

  const viewFromPositionChartMode = useOrgChartFlowStore(
    (state) => state.viewFromPositionChartMode,
  ) // State of the position to view from (focussed view)
  const setViewFromPositionChartMode = useOrgChartFlowStore(
    (state) => state.setViewFromPositionChartMode,
  ) // Function to set the state of viewFromPosition

  const setAddSeatLoading = useOrgChartFlowStore(
    (state) => state.setAddSeatLoading,
  ) // Function to set the state of addSeatLoading
  const setDeleteSeatLoading = useOrgChartFlowStore(
    (state) => state.setDeleteSeatLoading,
  ) // Function to set the state of deleteSeatLoading
  const setDeleteSeatAllocationLoading = useOrgChartFlowStore(
    (state) => state.setDeleteSeatAllocationLoading,
  ) // Function to set the state of deleteSeatAllocationLoading
  const isAllowedToEdit = useOrgChartFlowStore((state) => state.isAllowedToEdit) // State of if the user is allowed to edit the structure
  const setIsAllowedToEdit = useOrgChartFlowStore(
    (state) => state.setIsAllowedToEdit,
  ) // Function to set the state of isAllowedToEdit
  const membersThatCanEdit = useOrgChartFlowStore(
    (state) => state.membersThatCanEdit,
  )
  const setMembersThatCanEdit = useOrgChartFlowStore(
    (state) => state.setMembersThatCanEdit,
  )
  const groupsThatCanEdit = useOrgChartFlowStore(
    (state) => state.groupsThatCanEdit,
  )
  const setGroupsThatCanEdit = useOrgChartFlowStore(
    (state) => state.setGroupsThatCanEdit,
  )

  const [circularStructureReferences, setCircularStructureReferences] =
    useState([]) // This state is to hold any circular references that are found. We need to check otherwise reactflow will crash
  const [structureList, setStructureList] = useState(null) // The list of structure versions we have
  const [structureTitle, setStructureTitle] = useState('') // The title of the current structure so the user can edit
  const [published, setPublished] = useState(false) // The title of the current structure so the user can edit
  const [structureData, setStructureData] = useState([]) // The data of the structure we are displaying
  const [addStructureLoading, setAddStructureLoading] = useState(false) // This is used to determine if the user is adding a new structure or not
  const [resetStructureLoading, setResetStructureLoading] = useState(false) // This is used to determine if the user is adding a new structure or not
  const [deleteStructureLoading, setDeleteStructureLoading] = useState(false) // This is used to determine if the user is adding a new structure or not
  const [duplicateStructureLoading, setDuplicateStructureLoading] =
    useState(false) // This is used to determine if the user is adding a new structure or not
  const [currentStructure, setCurrentStructure] = useState(structureId) // The current version of the structure we are displaying
  const [triggerGetLatestPlan, setTriggerGetLatestPlan] = useState(false) // The current version of the structure we are displaying
  // chart data references anything to do with the basic org chart
  // structure data references anything to do with the advanced org chart (org chart with seat structure)
  const [chartRawData, setChartRawData] = useState([]) // The chart data for the basic org chart
  const [structureRawData, setStructureRawData] = useState([]) // The chart data for the advanced org chart
  const [isLocked, setIsLocked] = useState(false) // This is used to determine if the user is adding a new structure or not
  const [planOwner, setPlanOwner] = useState(null) // This is used to determine if the user is adding a new structure or not
  const [shareModalOpen, setShareModalOpen] = useState(false) // Whether the share modal is open or not
  const [editTitleEnabled, setEditTitleEnabled] = useState(false) // This is used to determine if the user is editing the title or not

  const proOptions = { hideAttribution: true }
  const didMountRef = useRef(true)
  const centerX = window.innerWidth / 2

  const { currentUser, hasRole } = useAuth()
  const isAdminAccess = hasRole(['ADMIN', 'OWNER', 'EDITOR', 'SUPERADMIN'])

  const organisationName = currentUser.parentData.name // This is used to fill the text with the organisation node

  const [structureNodesFinalised, setStructureNodesFinalised] = useNodesState(
    [],
  ) // The finalised version of the nodes to be sent to reactflow
  const [structureEdgesFinalised, setStructureEdgesFinalised] = useEdgesState(
    [],
  ) // The finalised version of the edges to be sent to reactflow
  const structureNodesFinalisedRef = useRef(structureNodesFinalised) // We need a reference to keep track of the latest version as callbacks from within the node are a bit iffy
  const structureDataRef = useRef(structureData) // Same here for the structure data

  // Memos are used to prevent unnecessary re-renders of the nodes. We supply the custom node names
  const nodeTypes = useMemo(
    () => ({
      InformerOrgChartStructureNode: InformerOrgChartStructureNode,
    }),
    [],
  )

  // This is used to add a new seat to the structure
  const addSeatToStructure = async (seatId, type, duplicate) => {
    const seatInfo = structureDataRef.current.find((seat) => seat.id === seatId)
    setAddSeatLoading(true)
    // We need to get the index of the next node. Note that because the node ID is sometimes a string and sometimes a number, we need to convert it to a number to get the max value
    const maxValue =
      structureDataRef.current.length > 0
        ? Math.max(...structureDataRef.current.map((item) => item.id))
        : 1
    const newIndex =
      maxValue === Infinity || maxValue === -Infinity || isNaN(maxValue)
        ? 1
        : maxValue + 1
    const newNode = {
      // We set the ID of the node to be the next highest number + 1 so that we don't have conflicts
      // We set the seat to be empty and ready to allocate
      id: newIndex.toString(),
      data: {
        id: newIndex.toString(),
        person: null,
        position: duplicate ? seatInfo.data.position : 'TBC',
        reportsToId: duplicate ? seatInfo.data.reportsToId : seatId,
        description: duplicate ? seatInfo.data.description : '',
        responsibilities: duplicate ? seatInfo.data.responsibilities : '',
        measurables: duplicate ? seatInfo.data.measurables : '',
        seatStatus: 'empty',
        type: type,
        ...commonFunctionMapping,
        minimised: false,
      },
      position: { x: 0, y: 0 },
      type: 'InformerOrgChartStructureNode',
    }

    const parentSeat = structureDataRef.current.find(
      (seat) => seat.id === seatId,
    )

    if (parentSeat) {
      parentSeat.data.hasChildren = true
    }
    // Update the states and update the database
    setStructureData([...structureDataRef.current, newNode])

    if (isAllowedToEdit && structureView) {
      await updateLayoutToDatabase([...structureDataRef.current, newNode])
    }

    setAddSeatLoading(false)
  }

  const setMinimised = (seatId, minimised) => {
    const currentData = structureDataRef.current
    const updatedData = currentData.map((seat) =>
      String(seat.id) === String(seatId)
        ? {
            ...seat,
            minimised: minimised,
            data: { ...seat.data, minimised: minimised },
          }
        : seat,
    )
    // Update the states and update the database
    setStructureData(updatedData)
    if (isAllowedToEdit && structureView) {
      updateLayoutToDatabase(updatedData)
    }
  }

  // Used to delete the seat allocation from the seat
  const deleteSeatAllocation = async () => {
    setDeleteSeatAllocationLoading(true)
    const currentData = structureDataRef.current
    const updatedData = currentData.map((seat) =>
      seat.id === editNode.id
        ? { ...seat, data: { ...seat.data, person: null, seatStatus: 'empty' } }
        : seat,
    )
    // Update the states and update the database
    setStructureData(updatedData)
    if (isAllowedToEdit && structureView) {
      await updateLayoutToDatabase(updatedData)
    }
    setDeleteSeatAllocationLoading(false)
  }

  // Used to delete the seat from the structure. Can only be done if there ar no users allocated
  const deleteSeat = async () => {
    setDeleteSeatLoading(true)
    const currentData = structureDataRef.current
    const updatedData = currentData
      .filter((seat) => seat.id !== editNode.id)
      .map((seat) =>
        seat?.data?.reportsToId?.toString() === editNode.id.toString()
          ? { ...seat, data: { ...seat.data, reportsToId: null } }
          : seat,
      )
    // Update the states and update the database
    setStructureData(updatedData)
    if (isAllowedToEdit && structureView) {
      await updateLayoutToDatabase(updatedData)
    }
    setDeleteSeatLoading(false)
  }

  // Used to update the superior of the seat. Ie, who the seat reports to (who is above it)
  const updateSeatSuperior = async (seatId, superiorId, allowOverride) => {
    const currentData = structureDataRef.current
    const updatedData = currentData.map((seat) => {
      if (seat.id === seatId) {
        return {
          ...seat,
          data: {
            ...seat.data,
            reportsToId: superiorId,
          },
        }
      }
      return seat
    })
    // Update the states and update the database
    setStructureData(updatedData)
    if ((allowOverride || isAllowedToEdit) && structureView) {
      await updateLayoutToDatabase(updatedData)
    }
  }

  // Handles the call when the user uses focus view
  const handleSetViewFromPosition = (seatId) => {
    setViewFromPosition(seatId)
  }

  // Handles the call when the user uses focus view
  const handleSetViewFromPositionChartMode = (seatId) => {
    setViewFromPositionChartMode(seatId)
  }

  // Since we can't pass functions as props to the nodes, we need to create a mapping of the functions and add them to the node data
  const commonFunctionMapping = {
    addSeatToStructureFunction: addSeatToStructure,
    updateSeatSuperiorFunction: updateSeatSuperior,
    setViewFromPositionFunction: handleSetViewFromPosition,
    setViewFromPositionChartModeFunction: handleSetViewFromPositionChartMode,
    setMinimisedFunction: setMinimised,
  }

  const duplicateBusinessStructure = async () => {
    setDuplicateStructureLoading(true)

    const updatedDataStructure = structureRawData.find(
      (item) => item.id === currentStructure,
    )

    updatedDataStructure.isPublished = false

    await createLayoutToDatabase(
      updatedDataStructure.orgChartData,
      `${structureTitle} (Copy)`,
    )
    setDuplicateStructureLoading(false)
  }

  // This is used to create a new structure. It takes the current chart map and creates a new structure from it
  const createBusinessStructure = async () => {
    setAddStructureLoading(true)
    setTriggerGetLatestPlan(true)
    const updatedData = []

    // Update the database
    await createLayoutToDatabase(updatedData, 'Blank Organisation Chart')
    setEditTitleEnabled(true)
    setAddStructureLoading(false)
  }

  // This is used to create a new structure in the database and then a callback will be performed to update the page
  const createLayoutToDatabase = async (structureData, title) => {
    try {
      const inputData = {
        orgChartData: structureData,
        name: title,
      }
      await createOrgChartLayout({ variables: { input: inputData } })
    } catch (err) {
      toast.error(err)
    }
  }

  const togglePublishedStructure = async () => {
    try {
      const changeToPublished = !published
      if (!currentStructure) {
        return
      }
      const _result = await updateOrgChartLayout({
        variables: {
          id: currentStructure,
          input: { published: changeToPublished },
        },
      })

      setPublished(changeToPublished)
    } catch (err) {
      toast.error(err)
    }
  }

  // This is used to update the structure in the database and then a callback will be performed to update the page
  const updateLayoutToDatabase = async (structureData) => {
    try {
      const inputData = {
        orgChartData: structureData,
      }
      if (!currentStructure) {
        return
      }

      await updateOrgChartLayout({
        variables: { id: currentStructure, input: inputData },
      })
    } catch (err) {
      toast.error(err)
    }
    const indexToOverwrite = structureRawData.findIndex(
      (obj) => obj.id === currentStructure,
    )

    const newStructure = [...structureRawData]

    newStructure[indexToOverwrite] = {
      ...newStructure[indexToOverwrite],
      ...{ orgChartData: structureData },
    }
    // Updating the structureRawData will cause the page to re-render and update the structure
    setStructureRawData(newStructure)
  }

  // This is used to return the edges from the nodes based on the "reports to"
  const returnEdgesFromData = (nodes) => {
    return nodes
      .map((person) => ({
        id: `e${person.id}-${person.reportsToId || ORG_NODE_ID}`, // We need to create a unique ID for the edge
        source: person.data.reportsToId
          ? person.data.reportsToId.toString()
          : ORG_NODE_ID, // If the person has a superior, we use that as the source, otherwise the seat reports to the organisation
        target: person?.id?.toString(),
        style: { strokeWidth: 3, stroke: '#bbb' },
        type: 'smoothstep',
      }))
      .filter((edge) => edge !== null)
  }

  // This is used to return the nodes in a hierarchy format.
  const returnHierarchyNodesFromData = (nodes, edges) => {
    if (!nodes || nodes.length === 0) {
      return [organizationStructureNode]
    }
    // We use d3 to create the hierarchy
    const hierarchy = stratify()
      .id((d: NodeType) => d.id)
      .parentId((d: NodeType) => edges.find((e) => e.target === d.id)?.source)([
      organizationStructureNode,
      ...nodes,
    ])
    // We use d3 to create the layout
    const layout = tree()
      .nodeSize([500, fullView || expandedNodes.length > 0 ? 800 : 325])
      .separation(() => 1)
    const root = layout(hierarchy)
    // We return the nodes with the positions
    return [organizationStructureNode, ...nodes].map((node) => {
      const { x, y } = root.find((d) => d.id === node.id) || node.position
      return { ...node, position: { x, y } }
    })
  }

  // This is used to delete a structure
  const deleteBusinessStructure = async () => {
    setDeleteStructureLoading(true)
    const indexToDelete = currentStructure
    await deleteOrgChartLayout({
      variables: {
        id: indexToDelete,
      },
    })

    const updatedData = structureRawData.filter(
      (item) => item.id !== indexToDelete,
    )

    setCurrentStructure(Math.min(...updatedData.map((struct) => struct.id)))

    // setStructureRawData(updatedData)
    setDeleteStructureLoading(false)
  }

  // Grab the new links to the avatar URL from the database
  const returnDataWithAvatars = (data) => {
    const newData = data.map((node) => {
      const correspondingChartData = chartRawData.find(
        (person) => person.id.toString() === node.data?.person?.id?.toString(),
      )
      if (correspondingChartData) {
        return {
          ...node,
          data: {
            ...node.data,
            person: {
              ...node.data.person,
              avatarUrl: correspondingChartData.avatarUrl, // Update avatarUrl
            },
          },
        }
      }
      return node
    })
    return newData
  }

  // Return the data with the seat statuses
  const returnDataWithSeatStatus = (data) => {
    const newData = data.map((node) => {
      const hasPerson = !!node.data?.person?.id

      // Check if the node's position matches any of the memberPosition names
      const isMatchingPosition =
        hasPerson &&
        node.data?.person?.memberPositions?.some(
          (memberPosition) => memberPosition.name === node.data?.position,
        )

      return {
        ...node,
        data: {
          ...node.data,
          seatStatus: !hasPerson
            ? 'empty'
            : isMatchingPosition
              ? 'match'
              : 'mismatch',
        },
      }
    })
    return newData
  }

  // Find people allocation and assign them to the states
  const setAllocationData = () => {
    // Get all of the people who have been allocated to a seat
    const structureIdsSet = new Set(
      structureData
        .filter((item) => item.data && item.data.person)
        .map((item) => item.data?.person?.id?.toString()),
    )
    // Get all of the people who have not been allocated to a seat
    const missingFromStructure = chartRawData.filter(
      (person) => !structureIdsSet.has(person.id.toString()),
    )
    // Create the list of people who have not been allocated to a seat
    const missingPeople = missingFromStructure.map((person) => ({
      value: person.id,
      label: person.name,
      active: person.isActive,
    }))
    // Create the list of people who have been allocated to a seat
    const inStructure = chartRawData.filter((person) =>
      structureIdsSet.has(person.id.toString()),
    )
    // Create the list of all people that have been allocated
    const allocatedPeople = inStructure.map((person) => ({
      value: person.id,
      label: person.name,
    }))
    // Create the list of all people in the org
    const allPeople = chartRawData.map((person) => ({
      value: person.id,
      label: person.name,
    }))

    setAvailableUsers(missingPeople)
    setAllUsers(allPeople)
    setAllocatedUsers(allocatedPeople)
  }

  // This is the organisation node for the structure. It is used to create the hierarchy as react flow always needs a single root node
  const organizationStructureNode = {
    id: ORG_NODE_ID,
    data: {
      position: organisationName,
      type: 'org',
      ...commonFunctionMapping,
    },
    position: { x: 0, y: 0 },
    type: 'InformerOrgChartStructureNode',
  }

  // This is used to detect circular references in the structure. If there are any, we need to warn the user and allocate the referent to report to the organisation as reactflow will crash if there are any
  function detectCircular(node, allNodes, visited = new Set()) {
    const nodeId = String(node.id) // Convert to string
    const reportsToId = String(node.data.reportsToId) // Convert to string

    if (visited.has(nodeId)) {
      node.data.reportsToId = ORG_NODE_ID
      return `${node.data.position}: ${node.data.person?.name || 'Unassigned'}`
    }
    visited.add(nodeId)
    const reportsToNode = allNodes.find((n) => String(n.id) === reportsToId)
    if (reportsToNode) {
      return detectCircular(reportsToNode, allNodes, visited)
    }
  }

  // This is used to update the structure data when the current structure changes
  useEffect(() => {
    if (structureRawData.length > 0) {
      const structureIndex = structureRawData.findIndex(
        (item) => item.id === currentStructure,
      )

      if (structureIndex === -1) {
        setStructureData([])
        setStructureTitle('')
        return
      }

      setStructureTitle(structureRawData[structureIndex].name)
      setPublished(structureRawData[structureIndex].published)
      setPlanOwner(structureRawData[structureIndex].createdByUser)

      const newData = structureRawData[structureIndex].orgChartData.map(
        (node) => ({
          ...node,
          data: {
            ...node.data,
            ...commonFunctionMapping,
          },
        }),
      )

      const withChildrenInfo = newData.map((node) => {
        const hasChildren = newData.some((childNode) => {
          return String(childNode.data.reportsToId) === String(node.id)
        })
        return { ...node, data: { ...node.data, hasChildren } }
      })

      setStructureData(withChildrenInfo)
      setIsAllowedToEdit(structureRawData[structureIndex].isAllowedToEdit)
      setGroupsThatCanEdit(structureRawData[structureIndex].groupsThatCanEdit)
      setMembersThatCanEdit(structureRawData[structureIndex].membersThatCanEdit)
    }
  }, [currentStructure])

  // Set the chart data for the basic org chart and the structure for the advanced org chart from the database
  useEffect(() => {
    const newChartData = memberships.map((item) => ({
      id: item?.id,
      name: item?.user?.name,
      avatarUrl: item?.user?.avatarUrl,
      position: item?.user?.position,
      reportsToId: item?.reportsTo?.id,
      reportsToName: item?.reports?.user?.name,
      minimised: !item?.reportsTo?.id,
      memberPositions: item?.memberPositions,
    }))

    setChartRawData(newChartData)

    const updatedDbList = dbList.map((structure) => {
      // Check if the structure has orgChartData and it is an array
      if (Array.isArray(structure.orgChartData)) {
        return {
          ...structure, // maintain other properties of the structure
          orgChartData: structure.orgChartData.map((node) => ({
            ...node, // maintain other properties of the node
            minimised: node.reportsToId === 'ORG_NODE' ? true : node.minimised,
          })),
        }
      }
      // if orgChartData is not an array, return the structure unmodified
      return structure
    })

    setStructureRawData(updatedDbList) // No modifications needed as the data comes in how we need it
  }, [memberships, dbList, structureView])

  // This is used to update the structure list when the structure raw data changes
  useEffect(() => {
    if (structureRawData.length > 0) {
      const filteredData = structureView
        ? structureRawData
        : structureRawData.filter((item) => item.published === true)

      setStructureList(
        filteredData
          .sort((a, b) => a.name.localeCompare(b.name)) // Sorting alphabetically by name
          .map((item) => ({ id: item.id, value: item.name })), // Mapping to the new structure
      )

      const newStructureId = triggerGetLatestPlan
        ? Math.max(...structureRawData.map((struct) => struct.id))
        : !structureView
          ? Math.max(...filteredData.map((struct) => struct.id))
          : isFinite(currentStructure)
            ? currentStructure
            : Math.max(...filteredData.map((struct) => struct.id))

      setCurrentStructure(isFinite(newStructureId) ? newStructureId : null)
    } else {
      setStructureList(null)
    }
  }, [structureRawData, structureView])

  // This is used to update the chart data when the chart raw data changes. This is where most of the data processing happens
  useEffect(() => {
    if (didMountRef.current) {
      didMountRef.current = false
      return
    }

    setAllocationData()

    // Create a working copy of the structure data
    const structureNodesMaximised = structureData.map((node) => ({
      ...node,
      data: {
        ...node.data,
      },
    }))

    const filterNodesWithoutMinimizedParents = (filteredNodes) => {
      const resultNodes = []

      // Enhanced checkParentMinimized to accept an additional parameter: visitedNodes
      const checkParentMinimized = (nodeId, visitedNodes = new Set()) => {
        // Check if we've already visited this node to prevent infinite recursion
        if (visitedNodes.has(nodeId)) {
          return false // Returning false to avoid excluding the node based on a circular reference
        }

        const parentNode = filteredNodes.find(
          (n) => String(n.id) === String(nodeId),
        )
        if (!parentNode) return false

        // Mark this node as visited
        visitedNodes.add(nodeId)

        if (parentNode.data && parentNode.data.minimised) return true

        // if the parent node reports to ORG_NODE_ID, it does not have any more parent.
        if (parentNode.data.reportsToId === ORG_NODE_ID) return false

        // Pass the updated visitedNodes set to the recursive call
        return checkParentMinimized(parentNode.data.reportsToId, visitedNodes)
      }

      for (const node of filteredNodes) {
        // Initialize an empty Set for each top-level node to track its ancestors
        if (!checkParentMinimized(node.data.reportsToId, new Set())) {
          resultNodes.push(node)
        }
      }

      return resultNodes
    }

    const structureNodesMinimised = filterNodesWithoutMinimizedParents(
      structureNodesMaximised,
    )

    // Map over structureData to update avatarUrl with the one from chartRawData
    const structureNodesFromData = returnDataWithAvatars(
      structureNodesMinimised,
    )

    // Find any circular references
    const circularStructureReferenceSet = structureNodesFromData.reduce(
      (acc, node) => {
        const reference = detectCircular(node, structureNodesFromData)
        if (reference) acc.add(reference)
        return acc
      },
      new Set(),
    )
    setCircularStructureReferences([...circularStructureReferenceSet])

    // Update the seat status based on if the person has been allocated to a seat or not, and check the allocation to see if it matches with the member management. This is used to choose the colour of the seat later on
    const updatedNodes = returnDataWithSeatStatus(structureNodesFromData)

    // Create the list of all positions
    const allPositionList = updatedNodes.map((node) => ({
      id: node.id,
      type: node.data.type,
      position: `${node.data.position}: ${
        node.data.type === 'seat'
          ? node.data.person?.name || 'Unassigned'
          : 'Entity'
      }`,
    }))
    allPositionList.sort((a, b) => a.position.localeCompare(b.position))
    setAllPositions([
      {
        id: ORG_NODE_ID,
        position: `${organisationName}: Organisation`,
        type: 'org',
      },
      ...allPositionList,
    ])

    const structureEdgesFromData = returnEdgesFromData(updatedNodes) // Get the edges from the structure nodes

    // Update the structure nodes and edges
    const newStructure = returnHierarchyNodesFromData(
      updatedNodes,
      structureEdgesFromData,
    )

    const filteredNodesAndEdges = (
      nodeId,
      structure,
      edges,
      nodesFiltered,
      edgesFiltered,
    ) => {
      const node = structure.find((n) => String(n.id) === String(nodeId))
      if (node) nodesFiltered.push(node)

      const childEdges = edges.filter(
        (e) => String(e.source) === String(nodeId),
      )
      for (const edge of childEdges) {
        edgesFiltered.push(edge)
        filteredNodesAndEdges(
          String(edge.target),
          structure,
          edges,
          nodesFiltered,
          edgesFiltered,
        )
      }
    }

    const filteredNodes = []
    const filteredEdges = []
    filteredNodesAndEdges(
      viewFromPosition,
      newStructure,
      structureEdgesFromData,
      filteredNodes,
      filteredEdges,
    )

    // Update the nodes and edges
    setStructureNodesFinalised(filteredNodes)
    setStructureEdgesFinalised(filteredEdges)
  }, [
    chartRawData,
    fullView,
    structureData,
    viewFromPosition,
    viewFromPositionChartMode,
    expandedNodes,
  ])

  const handleCloseSharingModal = () => {
    setShareModalOpen(false)
  }

  // We need to keep track of the latest version of the nodes and edges as callbacks from within the node are a bit iffy
  useEffect(() => {
    structureDataRef.current = structureData
  }, [structureData])

  // We need to keep track of the latest version of the nodes and edges as callbacks from within the node are a bit iffy
  useEffect(() => {
    structureNodesFinalisedRef.current = structureNodesFinalised
  }, [structureNodesFinalised])

  return (
    <Box sx={{ height: '95vh', width: '100%', overflow: 'hidden' }}>
      {/* // If there is no content, we need to show a message to the user to create
      it. Only for structured view*/}
      {structureView && !structureList && (
        <Stack className="flex h-full items-center justify-center">
          No plans have been created
          {/* // Button to create a new structure */}
          <Button
            loading={addStructureLoading}
            className="mt-4"
            fullWidth={false}
            startIcon={<ArrowPathIcon className="h-5 w-5" aria-hidden="true" />}
            onClick={createBusinessStructure}
          >
            Generate New Plan
          </Button>
        </Stack>
      )}

      {!structureView &&
        structureNodesFinalised?.length === 0 &&
        !isAdminAccess && (
          <Stack className="flex h-full items-center justify-center">
            No Content Available. Your administrator may not have created an
            organisation.
          </Stack>
        )}
      {((structureView && structureList) || !structureView) && (
        <>
          <div
            className="border-b bg-white p-10 py-4"
            data-testid="informer-org-chart-top-menu-bar-div"
          >
            <InformerOrgChartTopMenuBar
              structureTitle={structureTitle}
              setStructureTitle={setStructureTitle}
              setCurrentStructure={setCurrentStructure}
              structureList={structureList}
              currentStructure={currentStructure}
              updateOrgChartLayout={updateOrgChartLayout}
              circularStructureReferences={circularStructureReferences}
              isAllowedToEdit={isAllowedToEdit}
              membersThatCanEdit={membersThatCanEdit}
              groupsThatCanEdit={groupsThatCanEdit}
              published={published}
              editTitleEnabled={editTitleEnabled}
              setEditTitleEnabled={setEditTitleEnabled}
            />
          </div>
          <ReactFlow
            nodes={structureNodesFinalised}
            edges={structureEdgesFinalised}
            minZoom={0}
            defaultViewport={{ x: centerX, y: 200, zoom: 0.2 }}
            nodeTypes={nodeTypes} // Custom nodes defined above in the memo
            proOptions={proOptions}
            // fitView={true}
            edgesUpdatable={!isLocked}
            edgesFocusable={!isLocked}
            nodesDraggable={!isLocked}
            nodesConnectable={!isLocked}
            nodesFocusable={!isLocked}
            elementsSelectable={!isLocked}
          >
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />

            <InformerOrgChartMenuBar
              deleteBusinessStructure={deleteBusinessStructure}
              structureList={structureList}
              currentStructure={currentStructure}
              structureTitle={structureTitle}
              addStructureLoading={addStructureLoading}
              duplicateStructureLoading={duplicateStructureLoading}
              createBusinessStructure={createBusinessStructure}
              duplicateBusinessStructure={duplicateBusinessStructure}
              deleteStructureLoading={deleteStructureLoading}
              togglePublishedStructure={togglePublishedStructure}
              published={published}
              planOwner={planOwner}
              setShareModalOpen={setShareModalOpen}
              isAllowedToEdit={isAllowedToEdit}
            />
            {structureView && (
              <InformerOrgChartSidePanel
                chartRawData={chartRawData}
                structureData={structureData}
                setStructureData={setStructureData}
                updateLayoutToDatabase={updateLayoutToDatabase}
                deleteSeat={deleteSeat}
                deleteSeatAllocation={deleteSeatAllocation}
                isAllowedToEdit={isAllowedToEdit}
                structureView={structureView}
              />
            )}
            {currentStructure && (
              <InformerOrgChartSharingModal
                openModal={shareModalOpen}
                structureTitle={structureTitle}
                handleCloseModal={handleCloseSharingModal}
                planOwner={planOwner}
                currentStructure={currentStructure}
              />
            )}
          </ReactFlow>
        </>
      )}
    </Box>
  )
}

export default InformerOrgChart
